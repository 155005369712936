import { isDate } from 'date-fns';
import { format, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

//use parse from date-fns v1.9.0 http://cdn.date-fns.org/v1.9.0/date_fns.js
var MILLISECONDS_IN_HOUR = 3600000;
var MILLISECONDS_IN_MINUTE = 60000;
var parseTokenDateTimeDelimeter = /[T ]/;
var parseTokenPlainTime = /:/;
var parseTokenYYYY = /^(\d{4})-?/;
var parseTokenYYYYY = /^([+-]\d{4,6})-/;
var parseTokenMM = /^-(\d{2})$/;
var parseTokenDDD = /^-?(\d{3})$/;
var parseTokenMMDD = /^-?(\d{2})-?(\d{2})$/;
var parseTokenWww = /^-?W(\d{2})$/;
var parseTokenWwwD = /^-?W(\d{2})-?(\d{1})$/;
var parseTokenHH = /^(\d{2}([.,]\d*)?)$/;
var parseTokenHHMM = /^(\d{2}):?(\d{2}([.,]\d*)?)$/;
var parseTokenHHMMSS = /^(\d{2}):?(\d{2}):?(\d{2}([.,]\d*)?)$/;
var parseTokenTimezone = /([Z+-].*)$/;
var parseTokenTimezoneZ = /^(Z)$/;
var parseTokenTimezoneHH = /^([+-])(\d{2})$/;
var parseTokenTimezoneHHMM = /^([+-])(\d{2}):?(\d{2})$/;

export function parse(dateString) {
    if (isDate(dateString)) {
        return new Date(dateString.getTime());
    } else if (typeof dateString !== 'string') {
        return new Date(dateString);
    }
    var dateStrings = splitDateString(dateString);
    var date = parseDate(dateStrings.date);
    if (date) {
        var timeDetails = parseTimeDetails(dateStrings, date);
        return new Date(timeDetails.timestamp + timeDetails.time + timeDetails.offset * MILLISECONDS_IN_MINUTE);
    } else {
        return new Date(dateString);
    }
}

function splitDateString(dateString) {
    var dateStrings = {};
    var array = dateString.split(parseTokenDateTimeDelimeter);
    var timeString;
    if (parseTokenPlainTime.test(array[0])) {
        dateStrings.date = null;
        timeString = array[0];
    } else {
        dateStrings.date = array[0];
        timeString = array[1];
    }
    if (timeString) {
        var token = parseTokenTimezone.exec(timeString);
        if (token) {
            dateStrings.time = timeString.replace(token[1], '');
            dateStrings.timezone = token[1];
        } else {
            dateStrings.time = timeString;
        }
    }
    return dateStrings;
}
function parseDate(dateString) {
    var year;
    var yearToken;
    yearToken = parseTokenYYYY.exec(dateString) || parseTokenYYYYY.exec(dateString);
    if (yearToken) {
        var yearString = yearToken[1];
        year = parseInt(yearString, 10);
        dateString = dateString.slice(yearString.length);
    } else {
        return null;
    }
    var token;
    var date;
    var month;
    var week;
    if (dateString.length === 0) {
        date = new Date(0);
        date.setUTCFullYear(year);
        return date;
    }
    token = parseTokenMM.exec(dateString);
    if (token) {
        date = new Date(0);
        month = parseInt(token[1], 10) - 1;
        date.setUTCFullYear(year, month);
        return date;
    }
    token = parseTokenDDD.exec(dateString);
    if (token) {
        date = new Date(0);
        var dayOfYear = parseInt(token[1], 10);
        date.setUTCFullYear(year, 0, dayOfYear);
        return date;
    }
    token = parseTokenMMDD.exec(dateString);
    if (token) {
        date = new Date(0);
        month = parseInt(token[1], 10) - 1;
        var day = parseInt(token[2], 10);
        date.setUTCFullYear(year, month, day);
        return date;
    }
    token = parseTokenWww.exec(dateString);
    if (token) {
        week = parseInt(token[1], 10) - 1;
        return dayOfISOYear(year, week);
    }
    token = parseTokenWwwD.exec(dateString);
    if (token) {
        week = parseInt(token[1], 10) - 1;
        var dayOfWeek = parseInt(token[2], 10) - 1;
        return dayOfISOYear(year, week, dayOfWeek);
    }
    return null;
}
function parseTime(timeString) {
    var token;
    var hours;
    var minutes;
    token = parseTokenHH.exec(timeString);
    if (token) {
        hours = parseFloat(token[1].replace(',', '.'));
        return hours % 24 * MILLISECONDS_IN_HOUR;
    }
    token = parseTokenHHMM.exec(timeString);
    if (token) {
        hours = parseInt(token[1], 10);
        minutes = parseFloat(token[2].replace(',', '.'));
        return hours % 24 * MILLISECONDS_IN_HOUR + minutes * MILLISECONDS_IN_MINUTE;
    }
    token = parseTokenHHMMSS.exec(timeString);
    if (token) {
        hours = parseInt(token[1], 10);
        minutes = parseInt(token[2], 10);
        var seconds = parseFloat(token[3].replace(',', '.'));
        return hours % 24 * MILLISECONDS_IN_HOUR + minutes * MILLISECONDS_IN_MINUTE + seconds * 1000;
    }
    return null;
}
function parseTimezone(timezoneString) {
    var token;
    var absoluteOffset;
    token = parseTokenTimezoneZ.exec(timezoneString);
    if (token) {
        return 0;
    }
    token = parseTokenTimezoneHH.exec(timezoneString);
    if (token) {
        absoluteOffset = parseInt(token[2], 10) * 60;
        return token[1] === '+' ? -absoluteOffset : absoluteOffset;
    }
    token = parseTokenTimezoneHHMM.exec(timezoneString);
    if (token) {
        absoluteOffset = parseInt(token[2], 10) * 60 + parseInt(token[3], 10);
        return token[1] === '+' ? -absoluteOffset : absoluteOffset;
    }
}
function dayOfISOYear(isoYear, week, day) {
    week = week || 0;
    day = day || 0;
    var date = new Date(0);
    date.setUTCFullYear(isoYear, 0, 4);
    var diff = week * 7 + day + 1 - date.getUTCDay();
    date.setUTCDate(date.getUTCDate() + diff);
    return date;
}

function parseTimeDetails(dateStrings, date) {
    if(!date) date = parseDate(dateStrings.date);
    var timestamp,
        time = 0,
        offset,
        parsedOffset;
        
    if(date) {
        timestamp = date.getTime();
        if (dateStrings.time) time = parseTime(dateStrings.time);
        if (dateStrings.timezone) offset = parsedOffset = parseTimezone(dateStrings.timezone);
        else {
            offset = new Date(timestamp + time).getTimezoneOffset();
            offset = new Date(timestamp + time + offset * MILLISECONDS_IN_MINUTE).getTimezoneOffset();
        }
    }
    
    return {
        timestamp: timestamp,
        time: time,
        offset: offset || 0,
        parsedOffset: parsedOffset
    };
}

var dateFns = {
    isDate: isDate,
    //parse: require('date-fns/parse'),
    parse: parse,
    format: format,
    zonedTimeToUtc: zonedTimeToUtc,
    utcToZonedTime: utcToZonedTime,
    splitDateString: splitDateString,
    parseTimeDetails: parseTimeDetails
};

export default dateFns;