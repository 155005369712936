import Head from 'next/head';
import React from 'react';

import NoSsr from '../components/NoSsr';
import DateInput from '../components/DateInput';
import DateOutput from '../components/DateOutput';

import dateFns from '../utility/date-fns';
import { isValidDate, store } from '../utility/utility';
import timezones from '../data/timezones.json';

type HomeProps = {};

type HomeState = {
  date:string | undefined,
  timezone:string | undefined
};

class Home extends React.Component<HomeProps, HomeState> {
  private timeZoneOptions:Array<object> = [];
  private timeZoneGroups:Array<object> = [];
  private defaultTimeZone:string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  private defaultCountry:string = this.defaultTimeZone.split('/')[0] || 'America';
  private defaultGmtOffset:string = dateFns.format(new Date(), 'OOOO').replace(':', '');
  private defaultFormat:string = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';
  private mapTimezones:{ [key:string]:Array<string> } = {};
  private storage:any = store();

  constructor(props:HomeProps) {
    super(props);

    this.state = {
      date: undefined,
      timezone: undefined
    };
  }

  onDateChange(date:string | undefined) {
    this.setState({
      date: date
    });
  }

  onTimezoneChange(timezone:string | undefined) {
    this.setState({
      timezone: timezone
    });
  }

  componentDidMount() {
    this.initTimezones();

    //because we want to use state to control 
    this.setState({
      date: this.storage.date
    });
  }

  initTimezones() {
    const now:Date = new Date();

    let mapTimezone:Array<string>;
    let gmtOffset:string;
    let gmtOffset2:string;
    let timezoneParts:Array<string>;
    let timezone:string;
    let timeZoneOption:any;
    let group:string;

    timezones.forEach(item => {
      timezone = item.replace(/(UTC|Manual Offsets)\//, '').replace(/^UTC([+-])/, '$1');

      try {
        gmtOffset = dateFns.format(now, 'OOOO', {
          timeZone: timezone
        });
      }
      catch (exc) {
        console.warn(exc);
        return;
      }

      gmtOffset2 = gmtOffset.replace(':', '');

      //if(zonedDate == 'GMTZ') zonedDate = 'GMT+00:00';
      mapTimezone = this.mapTimezones[gmtOffset2] || (mapTimezone = this.mapTimezones[gmtOffset2] = []);
      mapTimezone.push(timezone);

      timezoneParts = item.replace(/_/g, ' ').split('/');
      timeZoneOption = {
        group: timezoneParts[0],
        value: timezone,
        text: timezoneParts[timezoneParts.length - 1],
        description: `${timezone} (${gmtOffset.replace('GMT', 'UTC')})`
      };

      this.timeZoneOptions.push(timeZoneOption);
      
      if(group != timeZoneOption.group) {
        group = timeZoneOption.group;
        this.timeZoneGroups.push({
          value: group,
          label: group
        });
      }

    });

    if (process.browser && window.location.href.indexOf('//localhost') >= 0) {
        (window as any).debug = {
          mapTimezones: this.mapTimezones,
          timeZoneOptions: this.timeZoneOptions,
          timeZoneGroups: this.timeZoneGroups,
          dateFns: dateFns
        }
    }
  }

  render() {
    // console.log('render', this);

    return (
      <div className="d-flex flex-column flex-grow-1">
        <Head>
          <title>Compare dates</title>
        </Head>
        <NoSsr>
          <DateInput
            date={this.storage.date}
            defaultTimeZone={this.defaultTimeZone}
            defaultCountry={this.defaultCountry}
            defaultGmtOffset={this.defaultGmtOffset}
            mapTimezones={this.mapTimezones}
            timezone={this.storage.timezone || this.defaultTimeZone}
            timeZoneOptions={this.timeZoneOptions}
            timeZoneGroups={this.timeZoneGroups}
            onDateChange={this.onDateChange.bind(this)}
            onTimezoneChange={this.onTimezoneChange.bind(this)}
          />
          <DateOutput
            enabled={isValidDate(dateFns.parse(this.state.date))}
            defaultFormat={this.defaultFormat}
            date={this.state.date || ''}
            timezone={this.state.timezone || this.defaultTimeZone}
            timeZoneOptions={this.timeZoneOptions}
            timeZoneGroups={this.timeZoneGroups}
          />
        </NoSsr>
      </div>
    );
  }
}

export default Home;