import React from 'react';
import Flatpickr from 'react-flatpickr';
// import 'flatpickr/dist/flatpickr.css';

// @ts-ignore
import TomSelect from 'tom-select/dist/js/tom-select.popular';
// import 'tom-select/dist/css/tom-select.bootstrap5.css';

import dateFns from '../utility/date-fns';
import { isValidDate, padLeft, formatString, store } from '../utility/utility';

type DateInputProps = {
  date:string,
  defaultTimeZone:string,
  defaultCountry:string,
  defaultGmtOffset:string,
  mapTimezones:{ [key: string]: Array<string> },
  timezone:string,
  timeZoneOptions:any,
  timeZoneGroups:any,
  onDateChange:Function,
  onTimezoneChange:Function,
};

class DateInput extends React.Component<DateInputProps> {
  private refDatePicker = React.createRef<Flatpickr>();
  private refTimeZone = React.createRef<HTMLSelectElement>();

  componentDidMount() {
    this.createTomSelectInstance();
  }

  componentDidUpdate() {
    const tomselect:any = (this.refTimeZone.current as any).tomselect;
    this.getGmtOffset(this.props.date) ? tomselect.disable() : tomselect.enable();
  }

  createTomSelectInstance () {
    const optionTemplate = function(data:any, escape:Function) {
      return formatString(
        data.text.startsWith('UTC') ? '<div>{0}</div>' : '<div>{0}<div class="ts-description">{1}</div></div>',
        escape(data.text),
        escape(data.description)
      );
    };

    new TomSelect(this.refTimeZone.current, {
      plugins: ['dropdown_input'],
      options: this.props.timeZoneOptions,
      maxOptions: this.props.timeZoneOptions.length,
      items: [this.props.timezone],
      optgroups: this.props.timeZoneGroups,
      optgroupField: 'group',
      // searchField: [{
      //   field:'value',
      //   weight:2
      // }],
      render: {
        item: optionTemplate,
        option: optionTemplate,
      },
    });
  }

  getGmtOffset(dateString:string, enableFallback:boolean=false):string {
    let gmtOffset:string | null = '';

    if(dateString) {
      const matchGmt = /(GMT|UTC)([-+])?(\d{0, 2}):?(\d{0, 2})?/i;
      let timeDetails = dateFns.parseTimeDetails(dateFns.splitDateString(dateString));
      let gmtOffsetMatches:RegExpMatchArray | null;

      if (timeDetails.timestamp) {
        if (timeDetails.parsedOffset != null) {
          const offset = timeDetails.parsedOffset;
          gmtOffset = 'GMT' + (offset <= 0 ? '+' : '-') + padLeft(Math.floor(Math.abs(offset / 60)), 2) + padLeft(Math.abs(offset % 60), 2);
        }
      }
      else if (gmtOffsetMatches = dateString.match(matchGmt)) {
        if (!gmtOffsetMatches[1]) gmtOffsetMatches[1] = '+';
        gmtOffsetMatches[2] = (gmtOffsetMatches[2] || '00').substr(0, 2) + (gmtOffsetMatches[3] || '00').substr(0, 2);
        gmtOffsetMatches[3] = '';
        gmtOffset = gmtOffsetMatches.join('');
      }

      if (!gmtOffset && enableFallback) {
        if (this.props.timezone != this.props.defaultTimeZone) {
          const selected:HTMLOptionElement = (this.refTimeZone.current as any).tomselect.activeOption;
          if (selected) {
            const matches = selected.innerText.match(matchGmt);
            gmtOffset = matches && matches[0];
          }
        }
        if (!gmtOffset) gmtOffset = this.props.defaultGmtOffset;
      }
    }

    return gmtOffset || '';
  }
  
  onDateChange(date:string) {
    let timezone:string | undefined;

    store({
      date: date
    });

    if (date) {
      if (isValidDate(dateFns.parse(date))) {
        // outputWrapper.classList.remove('d-none');
        // outputWrapper.style.opacity = outputWrapper.style.pointerEvents = '';
  
        // input.classList.remove('is-invalid');
        // (input.parentNode as HTMLElement).classList.remove('is-invalid');
  
        const gmtOffset:string = this.getGmtOffset(date, true);
        let parsedTimeZone:string | undefined;
        let mappedTimezone:Array<string>;
  
        if (gmtOffset && (mappedTimezone = this.props.mapTimezones[(gmtOffset as string)])) {
          if (!timezone) {
            if (gmtOffset == this.props.defaultGmtOffset) parsedTimeZone = this.props.defaultTimeZone;
            else if (gmtOffset == 'GMT+0000') parsedTimeZone = 'UTC';
            else {
              parsedTimeZone = mappedTimezone.find((item:string) => {
                return item.indexOf(this.props.defaultCountry) >= 0;
              });
              if (!parsedTimeZone) parsedTimeZone = mappedTimezone[0];
            }
  
            if (parsedTimeZone && parsedTimeZone != timezone) {
              timezone = parsedTimeZone;
            }
          }

          if(this.refTimeZone.current) {
            (this.refTimeZone.current as any).tomselect.setValue(timezone);
          }
        }
      }
    }

    this.props.onDateChange(date);
  }

  onTimezoneChange(value:string) {
    store({
      timezone: value
    });

    this.props.onTimezoneChange(value);
  }

  render() {
    // console.log('render', this);

    const parsedDate:any = this.props.date && dateFns.parse(this.props.date);
    const isDateValid:boolean = !this.props.date || isValidDate(parsedDate);
    const date:Date = this.props.date && isDateValid ? parsedDate : new Date();
    const gmtOffset:string = this.getGmtOffset(this.props.date);

    return (
      <form
        id="form-parse"
        name="form-parse"
        className="needs-validation"
        noValidate
      >
        <div className="row">
          <div className="col-md-7 col-lg-8">
            <div className="input-group">
              <input
                type="text"
                className={formatString('form-control {0}', isDateValid ? '' : 'is-invalid')}
                placeholder="Enter a date string. Example format, MM/dd/yyyy h:mm:ss a"
                aria-label="Enter a date string. Example format, MM/dd/yyyy h:mm:ss a"
                value={this.props.date}
                onInput={(event:React.SyntheticEvent) => {
                  this.onDateChange((event.target as HTMLInputElement).value);
                }}
              />
              <Flatpickr
                ref={this.refDatePicker}
                className="form-control"
                aria-hidden="true"
                value={date}
                onChange={dates => {
                  if(isValidDate(dates && dates[0])) {
                    this.onDateChange(dateFns.format(dates[0], 'MM/dd/yyyy h:mm:ss a'));
                  }
                }}
                options={{
                  // wrap: true,
                  allowInput: true,
                  enableTime: true,
                  enableSeconds: true,
                  disableMobile: true
                }}
              />
              <button
                type="button"
                className="btn btn-outline-secondary input-group-append"
                aria-label="Open date picker"
                onMouseDown={(event:React.FormEvent<HTMLButtonElement>) => {
                  //prevent Flatpickr toggle overfiring
                  event.nativeEvent.stopImmediatePropagation();
                }}
                onClick={() => {
                  if(this.refDatePicker.current) this.refDatePicker.current.flatpickr.toggle();
                }}
              >
                Pick
              </button>
            </div>
            <div className={formatString('invalid-feedback {0}', isDateValid ? '' : 'd-block')}>
              Try entering a date using a format of MM/dd/yyyy h:mm:ss a
            </div>
          </div>
          <div className="col-md-5 col-lg-4 mt-3 mt-md-0">
            <select
              ref={this.refTimeZone}
              className="form-control"
              disabled={gmtOffset ? true : false}
              placeholder="Search time zone"
              value={this.props.timezone}
              onInput={(event:React.FormEvent<HTMLSelectElement>) => {
                this.onTimezoneChange((event.target as HTMLSelectElement).value);
              }}
            >
            </select>
            <div className={formatString('invalid-feedback {0}', gmtOffset ? 'd-block' : '')}>
              Remove GMT offset from date to change
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default DateInput;