import dateFns from './date-fns'
const cdStoreKey:string = 'cd-storage';
let cdStore:any;

if(process.browser) {
  try {
    const cdStoreValue = localStorage.getItem(cdStoreKey);
    if(cdStoreValue) cdStore = JSON.parse(cdStoreValue);
  }
  catch(exc) {}
}
if(typeof(cdStore) != 'object') cdStore = {};
if(!cdStore.rows) cdStore.rows = [];

store(cdStore);

export function getString(value:any, defaultValue?:string):string {
  if(typeof(value) != 'string') {
      if(value === undefined || value == null) value = defaultValue || '';
      else value += '';
  }
  return value;
}

// function replaceAll(value, start, end) {
//     return value.split(start).join(end);
// }

export function formatString(value:string, ...other: string[]):string {
  return value ? value.replace(/{(\d+)}/g, (match, index) => {
      return getString(other[index]);
  }) : '';
}

export function padLeft(value:any, size:number):string {
  value = getString(value);
  while (value.length < size) value = '0' + value;
  return value;
}

export function isValidDate(date:any):boolean {
  return dateFns.isDate(date) && isFinite(date);
}

export function store(value:any=undefined):any {
  if(value !== undefined) {
    if(value !== cdStore) {
      for(let key in value) {
        cdStore[key] = value[key];
      }
    }

    if(process.browser) {
      try {
        localStorage.setItem(cdStoreKey, JSON.stringify(cdStore));
      }
      catch(exc){}
    }
  }
  return cdStore;
}