import React, { useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

// @ts-ignore
import TomSelect from 'tom-select/dist/js/tom-select.popular';
// import 'tom-select/dist/css/tom-select.bootstrap5.css';

import dateFns from '../utility/date-fns';
import { store, formatString } from '../utility/utility';

type DateOutputProps = {
  enabled:boolean,
  defaultFormat:string,
  date:string,
  timezone:string,
  timeZoneOptions:any,
  timeZoneGroups:any,
};

type DateOutputState = {
  rows:Array<any>,
};

class DateOutput extends React.Component<DateOutputProps, DateOutputState> {
  private storage:any = store();

  constructor(props:DateOutputProps) {
    super(props);

    this.state = {
      rows: this.storage.rows,
    };
  }

  onUpdateRow(rowIndex:number, changedRow:any) {
    const rows = this.state.rows.map((item, index) => {
      return rowIndex == index ? Object.assign(item, changedRow) : item;
    });

    store({
      rows: rows
    });

    this.setState({
      rows: rows
    });
  }

  onRemoveRow(rowIndex:number) {
    const rows:Array<any> = [];

    this.state.rows.forEach((item:any, index:number) => {
      if(index != rowIndex) {
        rows.push(item);
      }
    });

    store({
      rows: rows
    });

    this.setState({
      rows: rows
    });
  }

  componentDidMount() {
    //because we want to use state to control 
    this.setState({
      rows: this.storage.rows
    });
  }

  render() {
    // console.log('render', this);

    return (
      <div className="jumbotron mt-3 text-center flex-grow-1">
        <div
          className="output-wrapper"
          aria-hidden={!this.props.enabled}
        >
          {this.state.rows.map((item:any, index:number) =>
            <OutputRow
              key={index}
              index={index}
              date={this.props.date}
              format={item.format || this.props.defaultFormat}
              parentTimezone={this.props.timezone}
              timezone={item.timezone || this.props.timezone}
              timeZoneOptions={this.props.timeZoneOptions}
              timeZoneGroups={this.props.timeZoneGroups}
              onUpdateRow={this.onUpdateRow.bind(this)}
              onRemoveRow={this.onRemoveRow.bind(this)}
            />
          )}
          <button
            type="button"
            className="btn btn-primary btn-sm"
            aria-label="Add row"
            onClick={() => {
              if(this.state.rows.length < 6) {
                const row:any = {
                  format: this.props.defaultFormat,
                  timezone: this.props.timezone,
                };

                store({
                  rows: this.state.rows.concat(row)
                });

                this.setState({
                  rows: this.state.rows.concat(Object.assign({}, row))
                });
              }
            }}
          >
            + Compare
          </button>
          &nbsp;
          <button
            className="btn btn-link"
            aria-label="Clear all rows"
            onClick={() => {
              if(this.state.rows.length) {
                store({
                  rows: []
                });

                this.setState({
                  rows: []
                });
              }
            }}
          >
            Clear
          </button>
        </div>
      </div> 
    );
  }
}

export default DateOutput;

type OutputRowProps = {
  date:string,
  format:string,
  index:number,
  parentTimezone:string,
  timeZoneOptions:any,
  timeZoneGroups:any,
  timezone:string,
  onUpdateRow:Function,
  onRemoveRow:Function,
};

type OutputRowState = {
  date:string,
  showTooltip:boolean,
};

class OutputRow extends React.Component<OutputRowProps, OutputRowState> {
  private refTimeZone = React.createRef<HTMLSelectElement>();
  private refFormat = React.createRef<HTMLSelectElement>();

  constructor(props:OutputRowProps) {
    super(props);

    this.state = {
      date: '',
      showTooltip: false,
    };
  }

  componentDidMount() {
    this.createTomSelectInstance();
  }

  createTomSelectInstance() {
    let optionTemplate = function(data:any, escape:Function) {
      return formatString(
        data.text.startsWith('UTC') ? '<div>{0}</div>' : '<div>{0}<div class="ts-description">{1}</div></div>',
        escape(data.text),
        escape(data.description)
      );
    };

    new TomSelect(this.refTimeZone.current, {
      plugins: ['dropdown_input'],
      options: this.props.timeZoneOptions,
      maxOptions: this.props.timeZoneOptions.length,
      items: [this.props.timezone],
      optgroups: this.props.timeZoneGroups,
      optgroupField: 'group',
      render: {
        item: optionTemplate,
        option: optionTemplate,
      },
    });

    optionTemplate = function(data:any, escape:Function) {
      return formatString(
        '<div>{0}<div class="ts-description">{1}</div></div>',
        escape(data.text),
        escape(data.description)
      );
    };

    new TomSelect(this.refFormat.current, {
      options: [
        {
          value: 'MM/dd/yyyy h:mm:ss a',
          text: 'MM/dd/yyyy',
          description: 'h:mm:ss a (US)',
        },
        {
          value: 'dd/MM/yyyy h:mm:ss a',
          text: 'dd/MM/yyyy',
          description: 'h:mm:ss a (Europe)',
        },
        {
          value: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx',
          text: 'yyyy-MM-dd',
          description: 'THH:mm:ss.SSSxxx (ISO 8601)',
        },
        {
          value: 'EEE MMM dd yyyy HH:mm:ss \'GMT\'xx (zzzz)',
          text: 'EEE MMM dd yyyy',
          description: 'HH:mm:ss GMTxx (zzzz) (JavaScript)',
        },
      ],
      items: [this.props.format],
      render: {
        item: optionTemplate,
        option: optionTemplate,
      },
    });
  }

  render() {
    // console.log('render', this);

    let timezone:string = this.props.timezone;
    let date:string = '';

    if(this.props.date) {
      const parsedDate = dateFns.parse(this.props.date);
      // const utcDate:Date = this.props.parentTimezone == 'UTC' ? parsedDate : dateFns.zonedTimeToUtc(parsedDate, this.props.parentTimezone);
      const utcDate:Date = dateFns.zonedTimeToUtc(parsedDate, this.props.parentTimezone);
      const zonedDate:any = dateFns.utcToZonedTime(utcDate, timezone);
      const format:string = this.props.format;

      //handle RangeError: Expected Area/Location(/Location)* for time zone
      if(timezone.indexOf('GMT+00:00') >= 0) timezone = 'UTC';
      
      //date-fns-tz appears to have a format bug when escaping characters before xx, ex. 'GMT'xx
      date = dateFns.format(zonedDate, format, {
              timeZone: timezone
          })
          .replace("'", '');
    }

    return (
      <div className="row text-start mb-4">
        <div className="col-md-5">
          <DateInput
            index={this.props.index}
            date={date}
          />
        </div>
        <div className="col-md-4 mt-3 mt-md-0">
          <select
            ref={this.refTimeZone}
            value={this.props.timezone}
            className="form-control"
            placeholder="Search time zone"
            onInput={(event:React.FormEvent<HTMLSelectElement>) => {
              this.props.onUpdateRow(this.props.index, {
                timezone: (event.target as HTMLSelectElement).value
              });
            }}
          >
          </select>
        </div>
        <div className="col-md-3 mt-3 mt-md-0">
          <div className="d-flex">
            <div className="me-auto w-100">
              <select
                ref={this.refFormat}
                value={this.props.format}
                className="form-control ts-input-date-format"
                placeholder="Select date format"
                onInput={(event:React.FormEvent<HTMLSelectElement>) => {
                  this.props.onUpdateRow(this.props.index, {
                    format: (event.target as HTMLSelectElement).value
                  });
                }}
              >
              </select>
            </div>
            <div className="ms-2">
              <button
                type="button"
                className="btn btn-secondary"
                aria-label="Remove row"
                style={{
                  marginLeft: '-31px'
                }}
                onClick={() => {
                  this.props.onRemoveRow(this.props.index);
                }}
              >
                -
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type DateInputProps = {
  date:string,
  index:number,
};

const DateInput = (props:DateInputProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const refDate = React.useRef<HTMLInputElement>(null);

  return <>
    <input
        ref={refDate}
        type="text"
        value={props.date}
        className="form-control"
        aria-label="Formatted date"
        readOnly
        onFocus={(event:React.FormEvent<HTMLInputElement>) => {
          const target = (event.target as HTMLInputElement);
          let isCopied:boolean = false;
          try {
            target.select(); 
            target.setSelectionRange(0, 99999); /*For mobile devices*/
            document.execCommand('copy');
            isCopied = true;
          }
          catch(exc){
              console.warn(exc);
          }
          if(isCopied) {
            setShowTooltip(true);

            setTimeout(() => {
              setShowTooltip(false);
            }, 700);
          }
        }}
        onBlur={() => {
          setShowTooltip(false);
        }}
      />
      <Overlay
        target={refDate.current}
        show={showTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip
            id={`tooltip-${props.index}`}
            {...props}
          >
            Copied
          </Tooltip>
        )}
      </Overlay>
  </>;
}